<template>
    <div
        id="app"
        v-loading="mainLoading"
        element-loading-text="切换租户中！"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <el-container>
            <el-header>
                <titleUser @loadingState="loadingState" @openIndependentView="openIndependentView"></titleUser>
            </el-header>
            <div class="content" v-if="!isSndependentView">
                <nav-bar :routes="routes" @addNavTabs="addNavTabs" @obtainSideBar="obtainSideBar"></nav-bar>
                <el-main :class="{ 'isSideBar': sideBarRoutes.length > 0, 'isfolding': !sidebarState && sideBarRoutes.length > 0}">
                    <div class="el_left nav-sidebar">
                        <side-bar
                            class="sideBar"
                            :is-collapse="isCollapse"
                            :side-bar-routes="sideBarRoutes"
                            @addNavTabs="addNavTabs"
                        ></side-bar>
                        <div class="foldingMenu">
                            <span v-if="sidebarState" @click="foldSidebar">
                                <i class="iconfont iconshouqi1" title="折叠边栏"></i>
                            </span>
                            <span v-if="!sidebarState" @click="openSidebar">
                                <i class="iconfont iconzhankai2" title="展开边栏"></i>
                            </span>
                        </div>
                    </div>
                    <div class="el_left pageView">
                        <navTabs ref="ref_navTabs"></navTabs>
                        <router-view class="viewBox" v-loading="viewLoading"></router-view>
                    </div>
                </el-main>
            </div>
            <div class="independentView" v-else>
                <router-view class="viewBox el_left" v-loading="viewLoading"></router-view>
            </div>
        </el-container>
        <!-- 消息弹出 -->
        <transition name="slide-fade">
            <div class="message-wrapper" v-show="msgShow">
                <div class="msg-header">
                    <el-badge :value="msgList.length" class="item">
                        <h6 class="iconfont icontixing">
                            消息提醒
                        </h6>
                    </el-badge>

                    <div class="header-btns">
                        <div class="page-btn-wrapper" v-show="msgList.length > 1">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="上一条消息"
                                placement="top"
                            >
                                <span class="iconfont iconjiantouzuo" @click="prevPage"></span>
                            </el-tooltip>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="下一条消息"
                                placement="top"
                            >
                                <span class="iconfont iconjiantouyou" @click="nextPage"></span>
                            </el-tooltip>
                        </div>
                        <span @click="msgClose"><i class="el-icon-close"></i></span>
                    </div>
                </div>
                <div class="msg-main">
                    <div class="content-wrapper">
                        <p class="msg-time">
                            {{ currentMsg.time }}
                        </p>
                        <p class="mas-text">
                            {{ currentMsg.message }}
                        </p>
                    </div>
                </div>
                <div class="msg-btns">
                    <el-button @click="showMessageDetails = true">
                        查看详情
                    </el-button>
                    <el-button type="primary" @click="msgClose">
                        我知道了
                    </el-button>
                </div>
            </div>
        </transition>
        <!-- 消息详情弹出 -->
        <el-dialog :visible.sync="showMessageDetails" custom-class="messageDetailsDialog" :close-on-click-modal="false">
            <h4 class="iconfont icontixing">
                消息提醒({{ msgList.length }})
            </h4>
            <ul class="masList">
                <template v-for="(item, index) in msgList">
                    <li :key="`msgList_${index}`" :class="{'unreadMessage': item.isDot}">
                        <div class="msgHeader">
                            <div>
                                <i></i>
                                <span>{{ item.time }}</span>
                            </div>
                            <el-button
                                plain
                                type="primary"
                                v-if="item.isDot"
                                @click="modifyMessageStatus(item.id, index)"
                            >
                                标为已读
                            </el-button>
                            <el-button disabled v-else>
                                已读
                            </el-button>
                        </div>
                        <p class="msgText">
                            {{ item.message }}
                        </p>
                    </li>
                </template>
            </ul>
            <footer class="messageDetailsFoot">
                <el-button @click="showMessageDetails=false">
                    关闭
                </el-button>
                <el-button type="primary" @click="modifyAllMessageStatus(item)">
                    全部已读
                </el-button>
            </footer>
        </el-dialog>
    </div>
</template>

<script>
import { eventBus } from '@/common/eventBus.ts';
import titleUser from '@/components/menu_header/titleUser';
import navBar from '@/components/menu_header/navBar';
import navTabs from '@/components/menu_header/navTabs';
import sideBar from '@/components/menu_sidebar';
import cookie from '@/common/NIM/utils/cookie';
import { resetTokenAndClearUser } from '@/utils';

export default {
    // 应用入口页
    name: 'index',
    components: {
        titleUser,
        navBar,
        sideBar,
        navTabs,
    },
    data() {
        return {
            // 路由数据
            routes: [],
            // 边栏菜单数据
            sideBarRoutes: [],
            // element边栏状态11
            isCollapse: false,
            // 边栏菜单状态,true展开，false折叠
            sidebarState: true,
            // 切换菜单加载中状态
            mainLoading: false,

            // 消息列表list
            msgList: [],
            msgShow: false,
            // 当前显示消息
            currentMsg: {},
            // 当前显示消息索引
            currentMsgIndex: 0,
            // 消息详情
            showMessageDetails: false,

            reConnectTimes: 0,
            // 全局login
            viewLoading: false,
            // 独立视图（没有菜单：设置场景）
            isSndependentView: null,
        };
    },
    created() {
        // 保存版本标识[1普通版本，2高级版]
        this.$store.commit('setVersionMark', this.$takeTokenParameters('AuthorizationVersion'));

        const thisRoute = this.$route.path;
        if (thisRoute.indexOf('admin') > -1 || thisRoute.indexOf('helpCenter') > -1) {
            this.isSndependentView = true;
        } else {
            this.isSndependentView = false;
        }
        const routes = this.$store.getters.getMenuData;
        if (routes && routes.length > 0) {
            this.routes = routes;
        }
    },
    mounted() {
        this.gitSideBarRoutes();
        this.getNoreadCount();
        // 添加事件总线eventBus
        eventBus.$on('updateSysUnread', () => {
            this.getNoreadCount();
        });
        eventBus.$on('reConnectSignlar', () => {
            this.handleSignlarMsg();
        });
        this.handleSignlarMsg();
        const that = this;
        window.onbeforeunload = function () {
            that.$store.dispatch('logout');
        };
        // 检测用户是否使用初始密码
        this.checkUseInitPwd();
    },
    computed: {
        routeFlow() {
            return this.$store.state.menuData;
        },
    },
    methods: {
        // 处理signlar消息
        handleSignlarMsg() {
            /* 连接signlar */
            const that = this;
            const token = sessionStorage.getItem('token').split(' ')[1];
            const signalR = require('../../public/signalr/signalr.min');
            const connection = new signalR.HubConnectionBuilder().withUrl('/interfaceApi/signalr/signalRHubService', {
                accessTokenFactory: () => token,
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            }).build();
            // 禁用，退出登录
            connection.on('UserLoginOutMessage', function (data) {
                const dat = JSON.parse(data);
                if (dat.TenantId === that.$takeTokenParameters('TenantId') && dat.LogonRights.indexOf(1) === -1) {
                    that.$message.error('当前账号已失效，请重新登陆！');
                    setTimeout(() => {
                        cookie.delCookie('isNIM');
                        resetTokenAndClearUser();
                        that.$router.replace({ name: 'login' });
                        window.location.reload();
                    }, 300);
                }
            });
            connection.on('ReceiveMessage', function (data) {
                if (data.type === 1) {
                    eventBus.$emit('updateSysMsg');
                } else if (data.type === 99) {
                    that.$nextTick(() => {
                        that.updateNoticeMsgChange(data);
                    });
                    eventBus.$emit('updateNoticefyMsg');
                } else {
                    eventBus.$emit('updateOtherMsg', data);
                }
                eventBus.$emit('updateSysUnread');
            });
            // 推送生准备车辆
            connection.on('ProductPcbVehicleMessage', function (data) {
                eventBus.$emit('UpdateVehicleMessage', data);
            });
            // 推送车辆状态变更 3分钟预计、10分钟警告
            connection.on('TankCarFrozenMessage', function (obj) {
                eventBus.$emit('TankCarFrozenMessage', JSON.stringify(obj));
            });
            // 状态变更
            connection.on('TankCarStatusChangeMessage', function (obj) {
                if (obj && obj !== 'null') {
                    eventBus.$emit('TankCarStatusChangeMessage', JSON.stringify(obj));
                    eventBus.$emit('Monitoring', JSON.stringify(obj));
                    eventBus.$emit('CarStatus', JSON.stringify(obj));
                }
            });
            // 车辆位置
            connection.on('CoordinateMessage', function (obj) {
                eventBus.$emit('CoordinateMessage', JSON.stringify(obj));
            });
            // 生产线改变
            connection.on('TaskLineChangeMessage', function (obj) {
                if (obj && obj !== 'null') {
                    eventBus.$emit('TaskLineChangeMsg', JSON.parse(obj));
                }
            });
            // 车辆状态改变
            connection.on('MapCarChangeMessage', function (obj) {
                if (obj !== null) {
                    eventBus.$emit('MapCarChangeMsg', JSON.parse(obj));
                }
            });
            // 生产发货刷新列表
            connection.on('ReLoadProductTask', function (data) {
                eventBus.$emit('ReLoadProductTask', data);
            });

            // 司机变化消息
            connection.on('DriverChangeMessage', function (data) {
                eventBus.$emit('DriverChangeMessage', JSON.stringify(data));
            });

            // 操作生产排车、删除、换线 其他电脑推送消息
            connection.on('RefreshVehicle', function (data) {
                eventBus.$emit('RefreshVehicle', JSON.stringify(data));
            });
            // 指定供应单
            connection.on('TasklcClashAddMessage', function (data) {
                eventBus.$emit('TasklcClashAddMessage', data);
            });
            // 系统更新通知
            connection.on('SystemUpdateInfo', function (data) {
                that.sysUpdateMsg(data);
            });

            // 无人值守
            connection.on('WeighbridgeMessage', function (data) {
                eventBus.$emit('WeighbridgeMessage', data);
            });
            connection.start().then(function () {
                const uid = that.$takeTokenParameters('Uid');
                connection.invoke('AddConnRelation', uid).catch(function (err) {
                    return console.error(err.toString());
                });
            }).catch(function (err) {
                return console.error(err.toString());
            });

            // 执行signalr 自定义方法
            eventBus.$on('signalrInvoke', (methodName, args) => {
                connection.invoke(methodName, args).catch(function (err) {
                    console.error(err.toString());
                });
            });

            connection.onclose(() => {
                if (this.reConnectTimes <= 5) {
                    this.reConnectTimes++;
                    this.handleSignlarMsg();
                }
            });
        },

        // 打开消息弹窗
        updateNoticeMsgChange(data) {
            const msgListAll = [];
            this.$nextTick(() => {
                msgListAll.push({
                    id: data.message_id,
                    message: data.message,
                    time: data.message_time,
                    group: data.group,
                    isDot: true,
                });
                // this.$set(this, 'msgList', msgListAll);
                const unreadMsg = this.msgList.filter(item => item.isDot);
                this.msgList = [...unreadMsg, ...msgListAll];
                this.currentMsg = this.msgList[0];
                this.msgShow = true;
            });
        },

        // 关闭消息弹窗
        async msgClose() {
            const ids = [];
            this.msgList.map(item => {
                ids.push(item.id);
            });
            const res = await this.modifyAllMessageStatusRequst(ids);
            if (res) {
                this.msgShow = false;
                this.currentMsg = {};
                this.currentMsgIndex = 0;
            }
        },

        // 下一页
        async nextPage() {
            if (this.currentMsgIndex >= this.msgList.length - 1) {
                this.currentMsgIndex = this.msgList.length - 1;
                return;
            }
            const isDot = this.msgList[this.currentMsgIndex].isDot;
            const MsgId = this.msgList[this.currentMsgIndex].id;
            if (isDot) {
                await this.modifyMessageStatus(MsgId, this.currentMsgIndex);
            }
            this.currentMsgIndex++;
            this.currentMsg = this.msgList[this.currentMsgIndex];
        },

        // 上一页
        async prevPage() {
            if (this.currentMsgIndex === 0) {
                this.currentMsgIndex = 0;
                return;
            }
            const isDot = this.msgList[this.currentMsgIndex].isDot;
            const MsgId = this.msgList[this.currentMsgIndex].id;

            if (isDot) {
                await this.modifyMessageStatus(MsgId, this.currentMsgIndex);
            }
            this.currentMsgIndex--;
            this.currentMsg = this.msgList[this.currentMsgIndex];
        },

        // 消息详情-修改消息状态
        async modifyMessageStatus(Id, index) {
            const res = await this.modifyMessageStatusRequst(Id);
            if (res) {
                this.msgList[index].isDot = false;
            }
        },

        // 消息详情-修改所有未读消息状态
        async modifyAllMessageStatus() {
            const ids = [];
            this.msgList.forEach(item => {
                if (item.isDot) {
                    ids.push(item.id);
                }
            });
            if (ids.length > 0) {
                const res = await this.modifyAllMessageStatusRequst(ids);
                if (res) {
                    this.msgList.forEach(item => {
                        item.isDot = false;
                    });
                }
            }
        },

        // 获取侧边栏数据
        gitSideBarRoutes() {
            const thisRoute = this.$route.path;
            const newSideBarRoutes = this.routes.filter(item => thisRoute.indexOf(item.path) !== -1 && (item.children || []).length > 0);
            if (newSideBarRoutes.length > 0) {
                this.sideBarRoutes = newSideBarRoutes[0].children;
            }
        },
        // 点击一级菜单返回侧边栏数据
        obtainSideBar(data) {
            if (data.children) {
                this.sideBarRoutes = data.children;
            } else {
                this.sideBarRoutes = [];
            }
        },

        // 添加tabs数据
        addNavTabs(data) {
            this.$refs.ref_navTabs.addNavTabs(data);
        },

        // 拉取消息读取情况
        getNoreadCount() {
            this.$axios
                .get('/interfaceApi/message/MessageInfo/get_noread_count')
                .then(res => {
                    let count = 0;
                    res.map(item => {
                        count += item.ncount;
                    });
                    const sysUnread = {
                        sum: count,
                        sysMsgUnread: 0,
                        ccMsgUnread: 0,
                        backlogMsgUnread: 0,
                        noticeMsgUnread: 0,
                    };
                    if (res[0]) {
                        sysUnread.sysMsgUnread = res[0].ncount;
                    }
                    if (res[1]) {
                        sysUnread.ccMsgUnread = res[1].ncount;
                    }
                    if (res[2]) {
                        sysUnread.backlogMsgUnread = res[2].ncount;
                    }
                    if (res[3]) {
                        sysUnread.noticeMsgUnread = res[3].ncount;
                    }
                    window.window.$globalHub.$store.commit('setSysUnread', sysUnread);
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },

        // 切换用户加载中状态回调
        loadingState(data) {
            this.mainLoading = data;
        },

        // 打开独立视图
        openIndependentView(type) {
            if (type === 'setUp') { // 设置
                this.$router.push({ path: '/admin' });
            }
            if (type === 'helpCenter') { // 帮助中心
                this.$router.push({ path: '/helpCenter' });
            }
        },

        // 边栏菜单折叠
        foldSidebar() {
            this.isCollapse = true;
            this.sidebarState = false;
        },

        // 边栏菜单展开
        openSidebar() {
            this.isCollapse = false;
            this.sidebarState = true;
        },

        // 检测用户是否使用初始密码
        checkUseInitPwd() {
            const loginType = sessionStorage.getItem('loginType');
            if (loginType !== 'mock') {
                const IsResetPwd = this.$takeTokenParameters('IsResetPwd');
                if (IsResetPwd === '0') {
                    this.$toast({
                        title: false,
                        type: 'eject',
                        width: '7rem',
                        height: '5rem',
                        t_url: 'userinfo/fristSetPwd',
                        extr: {},
                    });
                }
            }
        },
        // 系统更新消息 弹出
        async sysUpdateMsg(data) {
            const res = await this.systemUpdatesRequst(data.id);
            if (res) {
                this.$toast({
                    title: false,
                    type: 'eject',
                    width: '8rem',
                    height: '6.4rem',
                    t_url: 'messageCenter/pages/page/components/dialogue/sysUpdate',
                    extr: {
                        msgTime: data.take_effect_time,
                        msgTxt: data.content,
                    },
                });
            }
        },

        // 修改消息状态
        modifyMessageStatusRequst(MsgId) {
            const path = `/interfaceApi/message/MessageInfo/message_read/${MsgId}`;
            return this.$axios.$put(path, { defEx: true });
        },
        // 修改所有消息状态
        modifyAllMessageStatusRequst(MsgIds) {
            const path = '/interfaceApi/message/MessageInfo/message_read';
            return this.$axios.$put(path, MsgIds, { defEx: true });
        },
        // 系统更新
        systemUpdatesRequst(ID) {
            const path = `/interfaceApi/message/system_update/can_read?id=${ID}`;
            return this.$axios.$get(path, { defEx: true });
        },
    },
    watch: {
        routeFlow(newVal) {
            this.routes = newVal;
        },
        $route(to) {
            if (
                to.path.indexOf('admin') > -1
                || to.path.indexOf('helpCenter') > -1
            ) {
                this.isSndependentView = true;
            } else {
                this.isSndependentView = false;
                this.gitSideBarRoutes();
            }
        },
        getNIMUnread(newVal) {
            this.NIMUnread = newVal;
        },
    },
    updated() {
        // 提交sdk连接请求
        const isNIM = cookie.readCookie('isNIM');
        if (isNIM === 'true') {
            this.$store.dispatch('connect');
        }
        this.$store.dispatch('updateRefreshState');
    },
    destroyed() {
        this.$store.dispatch('logout');
    },
};
</script>

<style lang="stylus" scoped>
.slide-fade-enter-active
    transition all .3s ease
.slide-fade-leave-active
    transition all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0)
.slide-fade-enter,
.slide-fade-leave-to
    transform translateY(2rem)
    opacity 0
#app
    height 100%
    .el-container
        height 100%
        background: #E7E8EB
        .el-header
            height 1.6rem !important
            padding 0
            background url('./../assets/images/header/headerBG.png') center no-repeat
            background-size: 100% auto
        >.content
            height calc(100% - 0.8rem)
            margin-top: -0.8rem
            .el-main
                height calc(100% - 0.5rem)
                padding 0
                overflow hidden
                margin 0 0.2rem 0.1rem
                .nav-sidebar
                    width 2.4rem
                    height 100%
                    display none
                    overflow hidden
                    position relative
                    transition 0.3s
                    border-radius: 2px
                    .foldingMenu
                        width: 100%
                        height 0.52rem
                        position absolute
                        bottom 0
                        box-shadow: 0px -2px 4px 0px rgba(230, 230, 230, 0.5);
                        background #fff
                        text-align: right
                        &:hover
                            span
                                color #2e2e2e
                        span
                            float left
                            width 100%
                            height 0.5rem
                            font-size 0.16rem
                            line-height 0.5rem
                        i
                            display inline-block
                            width 0.64rem
                            font-size 0.18rem
                            text-align center
                .pageView
                    width 100%
                    height 100%
                    margin-left: 0.1rem
                    overflow hidden
                    transition 0.3s
                    border-radius: 2px
                    background #fff
                    .viewBox
                        height calc(100% - 0.55rem)
                &.isSideBar
                    .nav-sidebar
                        display block
                    .pageView
                        width calc(100% - 2.5rem)
                &.isfolding
                    .nav-sidebar
                        width 0.64rem
                        transition 0.3s
                    .pageView
                        width calc(100% - 0.74rem)
                        transition 0.3s
        >.independentView
            height calc(100% - 0.6rem)
            .admin-view
                margin-top: -0.8rem
    // 消息弹出
    .message-wrapper
        width 4rem
        height 2.3rem
        padding 0.2rem
        box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.3);
        border-radius: 4px 0px 0px 0px;
        border: 1px solid #D2E2FF;
        border-top: 4px solid rgba(38, 159, 255, 0.5);
        background: #fff
        position fixed
        right 0
        bottom  0
        z-index 100
        &:before
            content ''
            width 0.6rem
            height: 4px
            background: #0078FB;
            border-radius: 8px;
            position: absolute
            top -4px
            left 0px
        .msg-header
            width 100%
            height 0.2rem
            display: flex
            justify-content space-between
            align-items center
            h6
                font-size 0.18rem
                line-height 0.2rem
                color #040C1C
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                &:before
                    font-size 0.2rem
                    margin-right: 0.12rem
            .header-btns
                display: flex
                justify-content space-between
                align-items center
                .page-btn-wrapper
                    margin-right 0.2rem
                    span
                        display: inline-block
                        width 0.16rem
                        height 0.16rem
                        font-size 0.14rem
                        line-height: 0.14rem
                        text-align: center
                        border 1px solid #C2C2C2
                        color #C2C2C2
                        margin-left: 0.1rem
                        cursor pointer
                        &:hover
                            color #0078FB
                            border-color #0078FB
        .msg-main
            height 1.35rem
            padding 0.16rem 0 0.16rem 0.3rem
            .msg-time
                font-size 0.14rem
                color #999999
                margin-bottom: 0.07rem
            .mas-text
                font-size 0.16rem
                color #555555
                line-height 0.24rem
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
        .msg-btns
            text-align: right
            button
                height 0.32rem
                font-size 0.14rem
                line-height: 0.3rem
                padding 0 0.2rem
                color #34495E
                background: #F0F0F0
                &:nth-of-type(2)
                    color #fff
                    background: #0078FB
    // 消息详情弹出
    >>>.messageDetailsDialog
        width 7rem
        .el-dialog__header,
        .el-dialog__body
            padding 0
        .el-dialog__close
            font-size 0.16rem
            color #fff
        .el-dialog__body
            background: url(./../assets/images/index/msgbg.png) left top no-repeat
            background-size: 100%
            padding: 0.3rem 0.14rem 0.6rem
            position: relative
            h4
                font-size 0.24rem
                line-height 0.24rem
                color #fff
                margin-bottom: 0.2rem
                padding 0 0.08rem
            .masList
                max-height 5rem
                overflow-y: auto
                li
                    padding 0.14rem 0.2rem 0.2rem
                    background: #FFFFFF;
                    border-radius: 8px;
                    border: 1px solid #DBE6F1;
                    margin-bottom 0.2rem
                    margin: 0.08rem 0.08rem 0.2rem 0.08rem
                    &.unreadMessage
                        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
                        border-radius: 8px;
                        border: 1px solid #AFCDEB;
                        .msgHeader
                            >div
                                i
                                    background #EE3A3A
                    .msgHeader
                        display flex
                        justify-content space-between
                        align-items center
                        margin-bottom: 0.04rem
                        >div
                            display flex
                            justify-content space-between
                            align-items center
                            i
                                width 0.1rem
                                height 0.1rem
                                border-radius: 50%
                                background: #C2C2C2
                                margin-right: 0.1rem
                        .el-button
                            min-width 0.64rem
                            height 0.24rem
                            padding 0 0.1rem
                            font-size 0.12rem
                    .msgText
                        font-size 0.16rem
                        line-height: 0.32rem
                        color #555
            .messageDetailsFoot
                text-align: center;
                background #FFFFFF
                box-shadow 0px -2px 6px 0px rgba(0,0,0,0.1000)
                position: absolute
                bottom 0
                left 0
                width 100%
                height 0.6rem
                button
                    width 1.6rem
                    height 0.4rem
                    margin: 0.1rem 0.2rem
                    padding 0
                    font-size 0.14rem
</style>
